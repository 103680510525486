import * as client from "oidc-client";

var config = {
  authority: process.env.REACT_APP_IDENTITYSERVER_API_URL,
  client_id: "elmvrs",
  post_logout_redirect_uri: window.location.origin + "/index.html",
  redirect_uri: window.location.origin + "/callback.html",

  // if we choose to use popup window instead for logins
  popup_redirect_uri: window.location.origin + "/popup.html",
  popupWindowFeatures:
    "menubar=yes,location=yes,toolbar=yes,width=1200,height=800,left=100,top=100;resizable=yes",

  // these two will be done dynamically from the buttons clicked, but are
  // needed if you want to use the silent_renew
  response_type: "code",
  scope: "openid ApiOne myprofile",

  // this will toggle if profile endpoint is used
  loadUserInfo: true,

  // silent renew will get a new access_token via an iframe
  // just prior to the old access_token expiring (60 seconds prior)
  silent_redirect_uri: window.location.origin + "/silent.html",
  automaticSilentRenew: true,

  monitorAnonymousSession: true,

  // will revoke (reference) access tokens at logout time
  revokeAccessTokenOnSignout: true,

  // this will allow all the OIDC protocol claims to be visible in the window. normally a client app
  // wouldn't care about them or want them taking up space
  filterProtocolClaims: false,
};

var mgr = new client.UserManager(config);

export function getUserName() {
  return new Promise((resolve, reject) => {
    mgr
      .getUser()
      .then((user) => {
        if (!user) {
          console.log("no user, attempting to sign in silently");
          mgr
            .signinSilent()
            .then(() => {
              mgr
                .getUser()
                .then((user) => {
                  localStorage.setItem("token-expires-at", user.expires_at);
                  localStorage.setItem("token", user.access_token);
                  resolve(user.profile.name);
                })
                .catch((err) => reject(err));
            })
            .catch((err) => reject(err));
          return;
        }
        localStorage.setItem("token-expires-at", user.expires_at);
        localStorage.setItem("token", user.access_token);
        resolve(user.profile.name);
      })
      .catch((err) => reject(err));
  });
}

export function login() {
  mgr.signinRedirect();
}

export function logout() {
  mgr.signoutRedirect().then(() => {
    sessionStorage.clear();
    localStorage.clear();
  });
}

export function changePassword() {
  // Navigate to IdentityServer to change the password, and navigate back to this page after it's done.
  window.location =
    process.env.REACT_APP_IDENTITYSERVER_API_URL +
    "/Users/ChangePassword?redirect_uri=" +
    window.location;
}
