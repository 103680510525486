import React from "react";
import logo from "./osmlogo.svg";
import "./App.css";
import * as login from "./login.js";
import Test from "./components/Test";
import "bootstrap/dist/css/bootstrap.min.css";

class App extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      signedIn: false,
      userName: "",
      logginError: false,
      showReportComponent: false,
    };
    this.onExcelClick = this.onExcelClick.bind(this);
    this.onGoBackHandler = this.onGoBackHandler.bind(this);
  }

  componentDidMount() {
    login
      .getUserName()
      .then((userName) => {
        this.setState({
          loading: false,
          signedIn: true,
          userName: userName,
          logginError: false,
          showReportComponent: false,
        });
        console.log("logged in as " + userName);
      })
      .catch((err) => {
        if (err.message.includes("iat is in the future")) {
          this.setState({
            loading: false,
            signedIn: false,
            userName: "",
            logginError: true,
            showReportComponent: false,
          });
        } else {
          this.setState({
            loading: false,
            signedIn: false,
            userName: "",
            logginError: false,
            showReportComponent: false,
          });
        }
        console.log("erroer feil er:", err.message);
      });
  }

  onExcelClick() {
    console.log("Trykket på excl");
    this.setState({
      showReportComponent: true,
    });
  }
  onGoBackHandler() {
    console.log("Trykket på onGoBackHandler");
    this.setState({
      showReportComponent: false,
    });
  }

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <h1>VRS - Vessel Reporting System</h1>
        </header>
        {!this.state.showReportComponent ? (
          <main className="main">
            <div className="info-box">
              <p>
                VRS start page. From here you can access VRS, and administrate
                your passwords.
              </p>
            </div>
            <br />
            <div>
              <div style={this.state.loading ? {} : { display: "none" }}>
                Loading...
              </div>
              <div style={this.state.loading ? { display: "none" } : {}}>
                <div
                  className="unknown-user"
                  style={this.state.signedIn ? { display: "none" } : {}}
                >
                  <button onClick={login.login}>Login</button>
                </div>
                <div
                  className="signed-in-user"
                  style={this.state.signedIn ? {} : { display: "none" }}
                >
                  <p>You are logged on as {this.state.userName}.</p>
                  <button onClick={login.logout}>Logout</button>
                  <button onClick={login.changePassword}>
                    Change Password
                  </button>
                </div>
              </div>
            </div>
            <div
              className="info-box-error"
              style={this.state.logginError ? {} : { display: "none" }}
            >
              <p>
                Login issues due to time settings on computer. Clock shall be
                set to UTC. If not possible to adjust on board. Please contact
                IT support and fleet.performance@osm.no
              </p>
            </div>
            <div className="navigation">
              <br />
              <a href="/vessel" className="App-link">
                Vessel VRS
              </a>
              <br />
              <a href="/office" className="App-link">
                Office VRS
              </a>
              <br />
              <a href="/port" className="App-link">
                Port App
              </a>
              <br />
              <a
                style={this.state.signedIn ? {cursor: 'pointer'} : { display: "none" }}
                onClick={this.onExcelClick}
                className="App-link"
              >
                Excel reports
              </a>
            </div>
            {/* <Test></Test> */}
          </main>
        ) : (
          <Test onGoBackHandler={this.onGoBackHandler}></Test>
        )}

        <footer className="App-footer">
          <p>Copyright © {new Date().getFullYear()} All rights reserved</p>
        </footer>
      </div>
    );
  }
}

export default App;
