import React, { useState, useEffect } from "react";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import {
  Container,
  Row,
  Col,
  Form,
  Modal,
  Button,
  Alert,
} from "react-bootstrap";
import "../index.css";
import DatePicker from "react-datepicker";
import * as axios from "axios";
import "react-datepicker/dist/react-datepicker.css";

export default function Test({ onGoBackHandler }) {
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const [vessels, setVessels] = useState([]);
  const [selectedVessel, setSelectedVessel] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [showVesselSelectList, setShowVesselSelectList] = useState(true);
  const [showErrorDialog, setShowErrorDialog] = useState(false);

  useEffect(() => {
    getVessels();
  }, []);

  const handleInputChange = (e) => {
    const vessel = vessels.find((item) => item.id === +e.target.value);
    if (!vessel) return;
    setSelectedVessel(vessel);
  };

  const submit = (e) => {
    getExcelReport();
  };
  const onGoBack = (e) => {
    if (onGoBackHandler) onGoBackHandler();
  };

  const getVessels = async () => {
    try {
      const response = await axios.get(
        process.env.REACT_APP_VRS_API_URL +
          "/api/vessel/getvesselsbyaccesslevel"
      );
      if (response.status !== 200) return;
      if (response.data.length === 1) {
        setShowVesselSelectList(false);
        setSelectedVessel(response.data[0]);
      }
      setVessels(response.data);
    } catch (error) {
      console.log("errror", error);
    }
  };

  const getExcelReport = async () => {
    try {
      const config = { responseType: "blob" };
      setIsLoading(true);
      const response = await axios.get(
        process.env.REACT_APP_VRS_API_URL + "/api/ExcelReport",
        {
          params: {
            vesselName: selectedVessel.vesselName,
            fromDate: fromDate,
            toDate: toDate,
          },
          responseType: "blob",
        }
      );

      if (response.status !== 200) setShowErrorDialog(true);

      const url = window.URL.createObjectURL(response.data);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "template.xlsx");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      setShowErrorDialog(true);
      console.log("errror", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      {showErrorDialog && (
        <Modal
          size="lg"
          show={showErrorDialog}
          onHide={() => setShowErrorDialog(false)}
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              Could not create report
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Alert variant="danger">
              <p>For the selected period, there is no data in VRS for this vessel</p>
            </Alert>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setShowErrorDialog(false)}>Close</Button>
          </Modal.Footer>
        </Modal>
      )}
      <div className="main">
        <Container>
          <br></br>
          <Row style={{ float: "left" }}>
            <Col md={4}>
              <button className="btn btn-primary" onClick={(e) => onGoBack(e)}>
                Back
              </button>
            </Col>
            <Col md={{ span: 4, offset: 4 }}></Col>
          </Row>
          <br></br>
          <br></br>
          <br></br>
          <br></br>
          <div class="row align-items-end">
            <div class="form-group col">
              <label style={{ float: "left" }}>Vessel</label>
              {showVesselSelectList ? (
                <>
                  <Form.Select
                    defaultValue="Select Vessel..."
                    onChange={(e) => handleInputChange(e)}
                  >
                    <option>Select Vessel...</option>
                    {vessels.map((vessel) => (
                      <option value={vessel.id}>{vessel.vesselName}</option>
                    ))}
                  </Form.Select>
                </>
              ) : (
                <>
                  <Form.Control
                    placeholder={selectedVessel?.vesselName}
                    disabled
                  />
                </>
              )}
            </div>
            <div class="form-group col">
              <label style={{ float: "left" }}>From date</label>
              <DatePicker
                dateFormat="dd.MM.yyyy"
                selected={fromDate}
                onChange={(date) => setFromDate(date)}
                className="form-control"
                customInput={
                  <input
                    type="text"
                    id="validationCustom01"
                    placeholder="First name"
                  />
                }
              />
            </div>
            <div class="form-group col">
              <label style={{ float: "left" }}>To date</label>
              <DatePicker
                dateFormat="dd.MM.yyyy"
                selected={toDate}
                onChange={(date) => setToDate(date)}
                className="form-control"
              />
            </div>
            <div class="form-group col">
              <button
                type="submit"
                className="btn btn-primary"
                disabled={isLoading}
                onClick={(e) => submit(e)}
              >
                {isLoading && (
                  <span
                    class="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                  ></span>
                )}
                <span> Submit</span>
              </button>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}
